var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customizer d-none d-md-block",
    class: {
      open: _vm.isCustomizerOpen
    }
  }, [_c('b-link', {
    staticClass: "\n      customizer-toggle\n      bg-info\n      d-flex\n      align-items-center\n      justify-content-center\n    ",
    on: {
      "click": function click($event) {
        _vm.isCustomizerOpen = !_vm.isCustomizerOpen;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SendIcon",
      "size": "20"
    }
  })], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Skin"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "skin-radio-group",
      "name": "skin",
      "options": _vm.skinOptions
    },
    model: {
      value: _vm.skin,
      callback: function callback($$v) {
        _vm.skin = $$v;
      },
      expression: "skin"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Router Transition",
      "label-cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "clearable": false,
      "label": "title",
      "options": _vm.routerTransitionOptions,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.routerTransition,
      callback: function callback($$v) {
        _vm.routerTransition = $$v;
      },
      expression: "routerTransition"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.layoutType === 'vertical',
      expression: "layoutType === 'vertical'"
    }],
    attrs: {
      "label": "Color"
    }
  }, _vm._l(_vm.colors, function (color, index) {
    return _c('div', {
      key: color,
      staticClass: "p-1 d-inline-block rounded mr-1 cursor-pointer",
      class: ["bg-".concat(color), {
        'border border-light': !index
      }, {
        'mark-active': _vm.themeColor === color
      }],
      on: {
        "click": function click($event) {
          _vm.themeColor = color;
        }
      }
    });
  }), 0)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.layoutType === 'vertical',
      expression: "layoutType === 'vertical'"
    }],
    attrs: {
      "label": "Navbar Color"
    }
  }, _vm._l(_vm.navbarColors, function (color, index) {
    return _c('div', {
      key: color,
      staticClass: "p-1 d-inline-block rounded mr-1 cursor-pointer",
      class: ["bg-".concat(color), {
        'border border-light': !index
      }, {
        'mark-active': _vm.navbarBackgroundColor === color
      }],
      on: {
        "click": function click($event) {
          _vm.navbarBackgroundColor = color;
        }
      }
    });
  }), 0), _c('b-form-group', {
    attrs: {
      "label": _vm.layoutType === 'vertical' ? 'Navbar Type' : 'Menu Type'
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "navbar-type",
      "options": _vm.navbarTypes
    },
    model: {
      value: _vm.navbarType,
      callback: function callback($$v) {
        _vm.navbarType = $$v;
      },
      expression: "navbarType"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Footer Type"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "footer-type",
      "options": _vm.footerTypes
    },
    model: {
      value: _vm.footerType,
      callback: function callback($$v) {
        _vm.footerType = $$v;
      },
      expression: "footerType"
    }
  })], 1)], 1), _c('div', {
    staticClass: "customizer-section  gap-1"
  }, [_c('label', {
    staticClass: "font-medium-3 mb-1"
  }, [_vm._v("Chat bubble display times")]), _c('div', [_c('span', [_vm._v(" Today start time: "), _c('b-form-input', {
    attrs: {
      "type": "time"
    },
    model: {
      value: _vm.todayStarttime,
      callback: function callback($$v) {
        _vm.todayStarttime = $$v;
      },
      expression: "todayStarttime"
    }
  })], 1), _c('span', [_vm._v(" Today end time: "), _c('b-form-input', {
    attrs: {
      "type": "time"
    },
    model: {
      value: _vm.todayEndtime,
      callback: function callback($$v) {
        _vm.todayEndtime = $$v;
      },
      expression: "todayEndtime"
    }
  })], 1), _c('span', [_vm._v(" Tomorrow start time: "), _c('b-form-input', {
    attrs: {
      "type": "time"
    },
    model: {
      value: _vm.toMorrowStarttime,
      callback: function callback($$v) {
        _vm.toMorrowStarttime = $$v;
      },
      expression: "toMorrowStarttime"
    }
  })], 1), _c('span', [_vm._v(" Tomorrow end time: "), _c('b-form-input', {
    attrs: {
      "type": "time"
    },
    model: {
      value: _vm.toMorrowEndtime,
      callback: function callback($$v) {
        _vm.toMorrowEndtime = $$v;
      },
      expression: "toMorrowEndtime"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex justify-content-end gap-2 mt-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.handleSave();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _vm._v(" OK ")], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }