/* eslint-disable no-return-assign */
import { groupBy, isEmpty } from 'lodash-es'

// ===================== resolveResponseSeatMap =====================
const EMPTY_ROW_CONFIG = {
  VJ: {
    320: [12, 14],
    321: [11, 27],
    330: [14, 15, 34],
  },
  // Cacs hãng khác
}

const LABEL = {
  isLabel: true,
  name: '',
}

const idGenerator = {
  layer1: 0,
  layer2: 0,
  layer3: 0,
  next(layer) {
    this[layer] += 1
    return `${layer}_${this[layer]}`
  },
  reset() {
    this.layer1 = 0
    this.layer2 = 0
    this.layer3 = 0
  },
}

const getAirlineEmptyRows = (source, aircraft) => EMPTY_ROW_CONFIG[source]?.[aircraft] || []

function getHeaderRowByConfig(cabinConfig, cabinConfigInternal) {
  const seatGroupCounts = cabinConfig.split('-').map(Number).filter(Boolean) // lọc bỏ block ở giữa với các cabinConfig = ["3-0-3", "2-0-2"]
  const columnLetters = cabinConfigInternal.split('-')

  const seatMap = columnLetters.map(it => ({
    id: idGenerator.next('layer3'),
    ...LABEL,
    name: it,
  }))

  let aisleIndex = 0
  seatGroupCounts.forEach((seats, index) => {
    aisleIndex += Number(seats)
    if (index < seatGroupCounts.length - 1) {
      seatMap.splice(aisleIndex, 0, { id: idGenerator.next('layer3'), ...LABEL }) // thêm lối đi
      aisleIndex += 1
    }
  })

  return seatMap
}

// ===================== LAYER_2 + 3 =====================
function getRowSeatMap(cabin, emptyRows = []) {
  const cabinSeatMap = []
  const headerRow = getHeaderRowByConfig(cabin.cabinConfig, cabin.cabinConfigInternal)

  cabinSeatMap.push({
    id: idGenerator.next('layer2'),
    items: headerRow,
  })

  const seatsByRow = groupBy(cabin.seatOptions, 'row')
  const rowMap = new Map(Object.entries(seatsByRow)) // NOTE: có thể cần sort() nếu data chưa sắp xếp: const rowMap = Object.keys(seatsByRow).map(Number).sort((a, b) => a - b);

  let previousRowNumber = 0
  for (const [currentRow, seats] of rowMap) {
    const currentRowNumber = Number(currentRow) // đoạn này có hãng trả là string..
    if (previousRowNumber && ((currentRowNumber - previousRowNumber) > 2)) {
      const emptyRow = { id: idGenerator.next('layer2'), items: [{ id: idGenerator.next('layer3'), ...LABEL }] }
      cabinSeatMap.push(emptyRow)
    }
    previousRowNumber = currentRowNumber
    // NOTE: Các hãng cần cách hàng ghế thì check ở đây, đang có VJ cần cách thôi
    if (emptyRows.includes(currentRowNumber)) {
      const emptyRow = { id: idGenerator.next('layer2'), items: [{ id: idGenerator.next('layer3'), ...LABEL }] }
      cabinSeatMap.push(emptyRow)
    }
    const rowSeats = {
      id: idGenerator.next('layer2'),
      items: headerRow.map(column => {
        if (!column.name) {
          return {
            id: idGenerator.next('layer3'), ...LABEL, name: currentRow,
          }
        }
        const matchingSeat = seats.find(seat => seat.col === column.name)
        return matchingSeat
          ? { id: idGenerator.next('layer3'), ...matchingSeat, name: `${matchingSeat.row}${matchingSeat.col}` }
          : { id: idGenerator.next('layer3'), ...LABEL }
      }),
    }
    cabinSeatMap.push(rowSeats)
  }
  return cabinSeatMap
}

export function resolveResponseSeatMap(res) {
  if (isEmpty(res?.items)) return []

  const listSeatMapValid = res.items.filter(sourceItem => !isEmpty(sourceItem?.seatsMap))
  if (isEmpty(listSeatMapValid)) return []

  const result = listSeatMapValid.flatMap(airlineSeatMapItem => airlineSeatMapItem.seatsMap.map(seatMap => {
    const { cabinDetails, aircraft, ...rest } = seatMap
    const source = airlineSeatMapItem.source
    const airline = airlineSeatMapItem.airline

    // push thêm hàng trống cho các exit row
    const emptyRows = getAirlineEmptyRows(source, aircraft)
    const seatLayouts = cabinDetails.map(cabin => ({
      id: idGenerator.next('layer1'),
      items: getRowSeatMap(cabin, emptyRows),
    }))

    const dataSeatMap = {
      ...rest,
      source,
      airline,
      aircraft,
      seatLayouts,
    }
    return dataSeatMap
  }))

  idGenerator.reset()
  return result
}

// =================== Details các loại màu ghế ===================
export const seatInfoList = [
  {
    title: 'Ghế còn trống',
    variant: 'info',
    class: 'cursor-default ',
  },
  {
    title: 'Ghế cạnh lối thoát',
    variant: 'info',
    class: 'cursor-default exit_seat_class',
  },
  {
    title: 'Ghế đã chọn',
    variant: 'success',
    class: 'cursor-default ',
  },
  {
    title: 'Đã có người đặt',
    variant: 'secondary',
    class: 'cursor-default ',
  },
]

// =================== resolveLocationBySeat ===================
const SEAT_MAPPINGS = {
  Window: ['WINDOW', 'Window', 'W', 'Window seat'],
  AisleSeat: ['AISLE', 'AisleSeat', 'A', 'Aisle', 'Aisle seat'],
  ExitRowSeat: ['EXITROW', 'ExitRowSeat', 'E', 'emergencyExit', 'Exit row seat', 'EXIT', 'Emergency Exit'],
  RightSideOfAircraft: ['RightSideOfAircraft'],
  LeftSideOfAircraft: ['LeftSideOfAircraft'],
  OverWingSeat: ['WING', 'OverWingSeat(S)'],
  CenterSectionSeat: [
    'CenterSectionSeat(S)',
    'Center',
    'Center seat (not window, not aisle)',
    'Center seat',
  ],
  LimitedRecline: ['limitedRecline'],
  BulkheadFront: ['BULKHEAD', 'bulkheadFront', 'Leg space seat', 'LEGX', 'Extra Leg Room'],
  FrontOfCabinClassCompartment: ['Front of cabin class/compartment'],
  SeatSuitableForAdultWithAnInfant: ['Seat suitable for adult with an infant'],
  BufferZoneSeat: ['Buffer zone seat'],
  ChargeableSeats: ['Chargeable seats'],
  RestrictedSeatGeneral: ['Restricted seat - General'],
  SeatWithBassinetFacility: ['Seat with bassinet facility'],
  NoFacilitySeatIndifferentSeat: ['No facility seat (indifferent seat)'],
  upfrontSeat: ['UPFX'],
  standardSeat: ['STDX'],
}
const KEYWORD_TO_LOCATION = new Map()
Object.entries(SEAT_MAPPINGS).forEach(([location, keywords]) => {
  keywords.forEach(keyword => {
    KEYWORD_TO_LOCATION.set(keyword, location)
  })
})
function splitLocation(locationValue = '', source = '') { // regex riêng cho VN1A vì trong value có '-'
  if (!locationValue) return []
  const delimiter = ['VN1A', 'VN1A_MT', 'JQ'].includes(source) ? ' | ' : /[\s|,-]+/ // ---- \s: khoảng trắng
  return locationValue.split(delimiter)
}
function mapToSeatCategories(locationList) {
  const categories = new Set(['seatIsAvailable'])
  if (locationList.length === 0) return Array.from(categories)
  locationList.forEach(token => {
    const category = KEYWORD_TO_LOCATION.get(token)
    if (category) {
      categories.add(category)
    }
  })
  return Array.from(categories)
}

export function resolveLocationBySeat(locationValue = '', source = '') { // locationValue đang nhận 3 gt: fancilities(VN1A), seatValue(VU), location(khác),
  const locationList = splitLocation(locationValue, source)
  const result = mapToSeatCategories(locationList)
  return result // Exp: ['seatIsAvailable', 'Window', 'AisleSeat', 'LimitedRecline']
}
// =================== resolveLocationBySeat ===================

export default {}
