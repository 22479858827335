<template>
  <!-- FIXME: app customizer is not visible -->
  <div
    class="customizer d-none d-md-block"
    :class="{ open: isCustomizerOpen }"
  >
    <!-- Toggler -->
    <b-link
      class="
        customizer-toggle
        bg-info
        d-flex
        align-items-center
        justify-content-center
      "
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="SendIcon"
        size="20"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section">
      <b-form-group label="Skin">
        <b-form-radio-group
          id="skin-radio-group"
          v-model="skin"
          name="skin"
          :options="skinOptions"
        />
      </b-form-group>

      <!-- <b-form-group label="Content Width">
        <b-form-radio-group
          id="content-width-radio-group"
          v-model="contentWidth"
          name="content-width"
          :options="contentWidthOptions"
        />
      </b-form-group> -->

      <!-- <b-form-group
        label="RTL"
        label-cols="10"
      >
        <b-form-checkbox
          v-model="isRTL"
          class="mr-0 mt-50"
          name="is-rtl"
          switch
          inline
        />
      </b-form-group> -->

      <b-form-group
        label="Router Transition"
        label-cols="6"
      >
        <v-select
          v-model="routerTransition"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          label="title"
          :options="routerTransitionOptions"
          :reduce="option => option.value"
        />
      </b-form-group>
    </div>

    <!-- <div class="customizer-section">
      <b-form-group label="Menu Layout">
        <b-form-radio-group
          v-model="layoutType"
          name="layout-type"
          :options="layoutTypeOptions"
        />
      </b-form-group>

      <div
        v-if="layoutType === 'vertical'"
        class="d-flex justify-content-between align-items-center mt-2"
      >
        <span class="font-weight-bold">Menu Collapsed</span>
        <b-form-checkbox
          v-model="isVerticalMenuCollapsed"
          name="is-vertical-menu-collapsed"
          class="mr-0"
          switch
          inline
        />
      </div>

      <div class="d-flex justify-content-between align-items-center mt-2">
        <span class="font-weight-bold">Menu Hidden</span>
        <b-form-checkbox
          v-model="isNavMenuHidden"
          name="is-menu-visible"
          class="mr-0"
          switch
          inline
        />
      </div>
    </div> -->
    <div class="customizer-section">
      <b-form-group
        v-show="layoutType === 'vertical'"
        label="Color"
      >
        <div
          v-for="(color, index) in colors"
          :key="color"
          class="p-1 d-inline-block rounded mr-1 cursor-pointer"
          :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': themeColor === color}]"
          @click="themeColor = color"
        />
      </b-form-group>
    </div>

    <div class="customizer-section">
      <b-form-group
        v-show="layoutType === 'vertical'"
        label="Navbar Color"
      >
        <div
          v-for="(color, index) in navbarColors"
          :key="color"
          class="p-1 d-inline-block rounded mr-1 cursor-pointer"
          :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': navbarBackgroundColor === color}]"
          @click="navbarBackgroundColor = color"
        />
      </b-form-group>

      <b-form-group :label="layoutType === 'vertical' ? 'Navbar Type' : 'Menu Type'">
        <b-form-radio-group
          v-model="navbarType"
          name="navbar-type"
          :options="navbarTypes"
        />
      </b-form-group>
    </div>

    <div class="customizer-section">
      <b-form-group label="Footer Type">
        <b-form-radio-group
          v-model="footerType"
          name="footer-type"
          :options="footerTypes"
        />
      </b-form-group>
    </div>
    <div class="customizer-section  gap-1">
      <label class="font-medium-3 mb-1">Chat bubble display times</label>
      <div>
        <span>
          Today start time: <b-form-input
            v-model="todayStarttime"
            type="time"
          />
        </span>
        <span>
          Today end time: <b-form-input
            v-model="todayEndtime"
            type="time"
          />
        </span>
        <span>
          Tomorrow start time: <b-form-input
            v-model="toMorrowStarttime"
            type="time"
          />
        </span>
        <span>
          Tomorrow end time: <b-form-input
            v-model="toMorrowEndtime"
            type="time"
          />
        </span>
      </div>

      <div class="d-flex justify-content-end gap-2 mt-2">
        <b-button
          variant="warning"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />

          OK
        </b-button>
      </div>
    <!-- </vue-perfect-scrollbar> -->
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BLink,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BButton,

  // BFormCheckbox,
} from 'bootstrap-vue'
import {
  ref,
} from '@vue/composition-api'

import useAppCustomizer from './useAppCustomizer'

export default {
  directives: {
    Ripple,
  },

  components: {
    BButton,
    BFormInput,
    BLink,
    BFormRadioGroup,
    BFormGroup,
    // BFormCheckbox,
    vSelect: () => import('vue-select'),
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      colors,
      themeColor,
    } = useAppCustomizer()
    const getLocalStorageValue = (key, defaultValue) => localStorage.getItem(key) || defaultValue

    const todayStarttime = ref(getLocalStorageValue('todayStarttime', '23:00'))
    const todayEndtime = ref(getLocalStorageValue('todayEndtime', '23:59'))
    const toMorrowStarttime = ref(getLocalStorageValue('toMorrowStarttime', '00:00'))
    const toMorrowEndtime = ref(getLocalStorageValue('toMorrowEndtime', '00:30'))

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(
        s => s.value === 'semi-dark',
      )
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const rangeDate = null
    const handleSave = () => {
      localStorage.setItem('todayStarttime', todayStarttime.value)
      localStorage.setItem('todayEndtime', todayEndtime.value)
      localStorage.setItem('toMorrowStarttime', toMorrowStarttime.value)
      localStorage.setItem('toMorrowEndtime', toMorrowEndtime.value)
      window.location.reload()
    }
    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      rangeDate,

      // Perfect Scrollbar
      perfectScrollbarSettings,
      toMorrowEndtime,
      toMorrowStarttime,
      todayEndtime,
      todayStarttime,
      colors,
      themeColor,
      handleSave,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 83px);
}
</style>
